@import "~bootswatch/dist/flatly/_variables.scss";
$secondary: #ddd;
$light: #fff;
$dark: #333;
$primary: rgba(244, 140, 6, 0.8);

$pagination-bg: $primary;
$pagination-hover-color: $white !default;
$pagination-hover-bg: darken($primary, 15%);
$pagination-active-bg: $pagination-hover-bg;
$pagination-disabled-bg: lighten($primary, 15%);

@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/_bootswatch.scss";

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Readex+Pro:wght@160..700&display=swap");

.dropstart .dropdown-toggle::before {
  display: none;
}

.progress-bar-overlay {
  color: #000;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif;
}

.profile {
  border-radius: 50%;
}

.badge.category {
  border-width: 0 !important;
  cursor: pointer;
}

.badge.category:hover {
  filter: brightness(90%);
  transition: filter 0.25s ease;
}

.catalog-card:hover {
  filter: brightness(90%);
  transition: filter 0.25s ease;
}
.editable-modal-title {
  transition: box-shadow 0.25s ease;
  border-radius: 5px; // Add this line to make the box shadow have rounded corners

  &:hover {
    box-shadow: 0 0 0 3px rgba($primary, 0.25);
  }
}

// .navbar.fixed-left {
//   position: sticky;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 1030;
//   align-items: center;
//   padding: 0;
//   font-size: x-large;
//   justify-content: flex-start;
//   border: 0 !important;
// }
// .navbar.fixed-left .navbar-nav {
//   flex-direction: row !important;
//   width: 100%;
//   justify-content: space-around;
// }

// .navbar.fixed-left .nav-link.active {
//   background: $primary !important;
//   color: $light !important;
// }

// .navbar.fixed-left .nav-link {
//   color: $dark !important;
//   width: 100%;
//   text-align: center !important;
//   border: solid #dee2e6 !important;
//   border-width: 0px 0px 1px 1px !important;
// }

// .navbar.fixed-left .navbar-brand {
//   margin: 0 !important;
//   padding: 0 1rem 0 1rem !important;
//   width: 100%;
//   text-align: center;
// }

// @include media-breakpoint-up(lg) {
//   .navbar.fixed-left {
//     position: fixed;
//     bottom: 0;
//     width: 5rem;
//     flex-flow: column nowrap;
//     border-right: 1px solid #dee2e6 !important;
//     flex-grow: 0;
//     flex-direction: column;
//   }

//   .navbar.fixed-left .navbar-brand {
//     padding: 1.5rem 0 1.5rem 0 !important;
//   }

//   .navbar.fixed-left .navbar-nav {
//     flex-direction: column !important;
//     width: 100%;
//     text-align: center;
//     color: black !important;
//   }

//   .navbar.fixed-left .nav-link {
//     border-width: 0 0 1px 0 !important;
//   }
// }
